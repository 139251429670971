import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/Layout"
import * as styles from "../../styles/firm-philosophy.module.css"
import SidebarLinks from "../../components/SidebarLinks"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function PracticeAreas() {
  return (
    <Layout>
      <GatsbySeo
        title="Law Practice Areas | Tecklenburg, Jenkins & Jacoby, LLC | Charleston, SC"
        description="Tecklenburg, Jenkins & Jacoby law practice areas of expertise. We offer Admiralty (Maritime Law), Litigation, Business Law, Transportation Law, Real Estate Law, Construction Law, and Environmental Law."
        keywords="Charleston, South Carolina, Maritime Attorney, Maritime Lawyer, Lawyer, Maritime, Attorney, Admiralty Attorney, Admiralty Lawyer, Admiralty, Business Attorney, Business Lawyer, Business, Railroad Attorney, Railroad Lawyer, Railroad, Litigation Attorney, Litigation Lawyer, Litigation, Construction Attorney, Construction Lawyer, Construction, Estate Attorney, Estate Lawyer, Estate"
        canonical="https://www.tecklaw.net/practice-areas/"
      />
      <section className={styles.header}>
        <section className={styles.sidebar}>
          <SidebarLinks />
        </section>
        <section className={styles.practiceAreas}>
          <div>
            <Link to="/practice-areas/south-carolina-admiralty-maritime-law">
              <h2>Admiralty</h2>
              <StaticImage
                src="../../images/south-carolina-admiralty-maritime-law/yacht.jpg"
                alt="South Carolina Yacht"
              />
            </Link>
            <Link to="/practice-areas/south-carolina-business-law">
              <h2>Business Law</h2>
              <StaticImage
                src="../../images/south-carolina-business-law/pen.jpg"
                alt="South Carolina Pen"
              />
            </Link>
            <Link to="/practice-areas/south-carolina-real-estate-law">
              <h2>Real Estate Law</h2>
              <StaticImage
                src="../../images/south-carolina-real-estate-law/buildings.jpg"
                alt="South Carolina Buildings"
              />
            </Link>
            <Link to="/practice-areas/south-carolina-environmental-law">
              <h2>Environmental Law</h2>
              <StaticImage
                src="../../images/south-carolina-environmental-law/hazmat.jpg"
                alt="South Carolina Hazmat Suit"
              />
            </Link>
          </div>
          <div>
            <Link to="/practice-areas/south-carolina-litigation">
              <h2>Litigation</h2>
              <StaticImage
                src="../../images/south-carolina-litigation/gavelbook.jpg"
                alt="South Carolina Gavel on Book"
              />
            </Link>
            <Link to="/practice-areas/south-carolina-transportation-law">
              <h2>Transportation Law</h2>
              <StaticImage
                src="../../images/south-carolina-transportation-law/truck.jpg"
                alt="South Carolina Truck"
              />
            </Link>
            <Link to="/practice-areas/south-carolina-construction-law">
              <h2>Construction Law</h2>
              <StaticImage
                src="../../images/south-carolina-construction-law/hardhat.jpg"
                alt="South Carolina Hardhat"
              />
            </Link>
          </div>
        </section>
      </section>
    </Layout>
  )
}
